<template>
	<NavbarFreefile v-if="isFreefile" />
	<Navbar v-else />
	<MobileMenuFreefile v-if="isFreefile" />
	<MobileMenu v-else />
	<router-view />
	<FooterFreefile v-if="isFreefile" />
	<FooterSpanish v-else-if="isSpanish" />
	<Footer v-else />
</template>

<script setup>
import "@/scss/styles.scss"
import {defineAsyncComponent, ref, toRaw, watch} from "vue"
import {useRoute} from "vue-router"
import Navbar from "@/components/Navbar"
import NavbarFreefile from "@/components/NavbarFreefile"
import MobileMenu from "@/components/MobileMenu"
import MobileMenuFreefile from "@/components/MobileMenuFreefile"
import LoadingBlank from "@/components/LoadingBlank"
import thCookie from "@/functions/thCookie"
import getDate from "@/functions/getDate"
import getConfigs from "@/functions/getConfigs"
import getPrices from "@/functions/getPrices"
// import generatePageListCSV from "@/functions/generatePageListCSV"
import {store} from "@/functions/store"

const route = useRoute()

thCookie()
getConfigs()
getPrices()

const isFreefile = ref(false)
const isSpanish = ref(false)
if (location.href.includes("freefile")) {
	isFreefile.value = true
}
if (location.href.includes("espanol")) {
	isSpanish.value = true
}

watch(route, () => {
	if (location.href.includes("espanol")) {
		document.querySelector("html").setAttribute("lang", "es")
	}
	else {
		document.querySelector("html").setAttribute("lang", "en-us")
	}
})

const Footer = defineAsyncComponent({
	// the loader function
	loader: () =>
		new Promise((resolve) => {
			setTimeout(() => {
				resolve(import("@/components/Footer"))
			}, 100)
		}),

	// A component to use while the async component is loading
	loadingComponent: LoadingBlank,
	// Delay before showing the loading component. Default: 200ms.
	delay: 500
})
const FooterFreefile = defineAsyncComponent({
	// the loader function
	loader: () =>
		new Promise((resolve) => {
			setTimeout(() => {
				resolve(import("@/components/FooterFreefile"))
			}, 100)
		}),

	// A component to use while the async component is loading
	loadingComponent: LoadingBlank,
	// Delay before showing the loading component. Default: 200ms.
	delay: 500
})
const FooterSpanish = defineAsyncComponent({
	// the loader function
	loader: () =>
		new Promise((resolve) => {
			setTimeout(() => {
				resolve(import("@/components/espanol/Footer"))
			}, 100)
		}),

	// A component to use while the async component is loading
	loadingComponent: LoadingBlank,
	// Delay before showing the loading component. Default: 200ms.
	delay: 500
})

// for time travel testing logs
const isTimeTravelTesting = process.env.VUE_APP_TIME_TRAVEL
if (isTimeTravelTesting === "true" && location.href.includes("date=")) {
	const showTestConsoleLogs = async () => {
		console.log("%cTime Travel!", "font-weight:bold;font-size:2rem;")
		console.log(getDate())
		console.log("configs", toRaw(store.configs))
		console.log("prices", toRaw(store.prices))
	}
	setTimeout(showTestConsoleLogs, 1000)
}

// for generating page list csv
// const generatePageList = true
// if (process.env.VUE_APP_ENV_NAME === "prod" &&
// 	process.env.VUE_APP_ENV_NAME !== "prod" &&
// 	process.env.VUE_APP_ENV_NAME !== "stage" &&
// 	process.env.VUE_APP_ENV_NAME !== "loadtest" &&
// 	process.env.VUE_APP_ENV_NAME !== "test" &&
// 	generatePageList) {
// 	generatePageListCSV()
// }
</script>

<style lang="scss">
.router-link-active {
	&.mobile-menu-link {
		box-shadow: none;
	}
}
</style>
